





































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import Draggable from 'vuedraggable';
import { useAccessControl } from '../composable';
import { AccessLevel } from '../constants/access-levels.constants';
import { ConditionValue, ExceptionPolicy, GeneralPolicy, IndividualCondition } from '../models'; // IndividualCondition, ConditionValue
import AccessPolicyEdit from './AccessPolicyEdit.vue';
import AccessPolicyNode from './AccessPolicyNode.vue';
import { Field, Operant } from '../constants'; // Operant
import { AuthzResourceType } from '../constants/authz-resource-type.constant';
import { ExceptionFieldMappings, ExceptionPolicyFields, OperatorNames } from '@/modules/asset/constants'; // OperatorNames

export default defineComponent({
    name: 'AccessPolicy',
    model: {
        prop: 'accessControl',
        event: 'update-access-control',
    },
    props: {
        element: {
            type: String,
            required: true,
        },
        identifier: {
            type: [String, Number],
        },
        policies: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        organisationId: {
            type: Number,
            default: null,
        },
        creatorId: {
            type: Number,
            required: true,
        },
        accessLevel: {
            type: String,
        },
    },
    components: {
        AccessPolicyNode,
        Draggable,
        AccessPolicyEdit,
    },
    setup(props: any, { emit }: { emit: any }) {
        const loading = ref(true);
        const error = ref(null);
        const showAddPolicy = ref(false);
        const policyInEditMode = ref<number | null>(null);
        const generalPolicy = ref<GeneralPolicy | null>(null);
        const processedPolicies = ref<ExceptionPolicy[]>([]);
        const pendingChangePolicy = ref(false);
        const defaultPolicy = ref<GeneralPolicy>(GeneralPolicy.DENY_ALL);

        const fields = computed(() => {
            const filteredFields: Field[] = [];

            Field.selectiveSharingFields().forEach((field: Field) => {
                const fieldExists = processedPolicies.value.find((processedPolicy: any) => {
                    if (field.key === 'org.country' || field.key === 'org.continent')
                        return ['org.country', 'org.continent'].includes(processedPolicy.conditions[0].field.key);

                    return processedPolicy.conditions[0].field.key === field.key;
                });

                if (!fieldExists) filteredFields.push(Field[ExceptionFieldMappings[ExceptionPolicyFields[field.key]]]);
            });

            return filteredFields;
        });

        const filteredPolicies = computed(() => {
            let policies = R.clone(props.policies);
            // if access level is selective sharing, remove own organisation exception
            if (props.accessLevel === AccessLevel.SelectiveSharing) {
                policies = getAccessPoliciesJSON(policies);
                policies = policies.filter(
                    (policy: any) =>
                        policy.conditions.length === 0 ||
                        (policy.conditions.length && policy.conditions[0].field !== 'org.id'),
                );
            }

            return policies;
        });

        const processRetrievedExceptionPolicies = (policy: any, policyField: Field) => {
            const conditionValues = [];
            const values = policy.value;
            const operator = policy.operator;

            for (let i = 0; i < values.length; i++)
                conditionValues.push(new ConditionValue(values[i], policyField.key));

            const processedPolicy: ExceptionPolicy = new ExceptionPolicy(true, [
                new IndividualCondition(
                    policyField,
                    operator === OperatorNames.IN ? Operant.EQUALS : Operant.NOT_EQUALS,
                    conditionValues,
                ),
            ]);

            return processedPolicy.toJSON();
        };

        const getAccessPoliciesJSON = (assetPolicies: any) => {
            const json: any = [];
            const caveats = assetPolicies.find((accessPolicy: any) => accessPolicy.caveat);
            if (caveats) {
                const policies = caveats.caveat.context.policies;
                policies.forEach((policy: any) => {
                    const key = Object.keys(policy);
                    if (key.length) {
                        const extractedFieldName = key[0];
                        json.push(
                            processRetrievedExceptionPolicies(
                                policy[extractedFieldName],
                                Field[ExceptionFieldMappings[extractedFieldName]],
                            ),
                        );
                    }
                });
            }
            return json;
        };

        useAccessControl(filteredPolicies.value, defaultPolicy.value)
            .initialize()
            .then((result: { generalPolicy: GeneralPolicy | null; policies: ExceptionPolicy[] }) => {
                generalPolicy.value = result.generalPolicy === null ? defaultPolicy.value : result.generalPolicy;
                processedPolicies.value = result.policies;
                emit('update-access-control', {
                    generalPolicy: generalPolicy.value,
                    policies: processedPolicies.value,
                });
            })
            .catch((e) => {
                error.value = e;
                throw e;
            })
            .finally(() => {
                loading.value = false;
            });

        const defaultPolicyEffect = computed(() => generalPolicy.value && !generalPolicy.value.allow);

        const policyMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            if (oldIndex < newIndex) {
                processedPolicies.value = R.move(oldIndex, newIndex - 1, processedPolicies.value);
            } else if (oldIndex > newIndex) {
                processedPolicies.value = R.move(oldIndex, newIndex + 1, processedPolicies.value);
            }
        };

        const editPolicyException = (policyToEditIndex: number) => {
            policyInEditMode.value = policyToEditIndex;
        };

        const deletePolicyException = (policyToDeleteIndex: number) => {
            processedPolicies.value.splice(policyToDeleteIndex, 1);
        };

        const addPolicy = (policy: ExceptionPolicy) => {
            processedPolicies.value.push(policy);
            showAddPolicy.value = false;
        };

        const changeGeneralPolicy = (policy: GeneralPolicy) => {
            if (pendingChangePolicy.value) {
                pendingChangePolicy.value = false;
                generalPolicy.value = policy;
                for (let i = 0; i < processedPolicies.value.length; i++) {
                    processedPolicies.value[i].allow = !policy.allow;
                }

                emit('update-access-control', {
                    generalPolicy: generalPolicy.value,
                    policies: processedPolicies.value,
                });
            } else {
                pendingChangePolicy.value = true;
                setTimeout(() => {
                    pendingChangePolicy.value = false;
                }, 10000);
            }
        };

        return {
            error,
            generalPolicy,
            processedPolicies,
            defaultPolicyEffect,
            showAddPolicy,
            policyInEditMode,
            GeneralPolicy,
            policyMoved,
            addPolicy,
            editPolicyException,
            deletePolicyException,
            changeGeneralPolicy,
            pendingChangePolicy,
            AccessLevel,
            fields,
            AuthzResourceType,
            loading,
        };
    },
});
